import React, { useEffect, useState } from "react";
import ReactPlayer from 'react-player'
import LoadExternalScript from "../utils/load-external-script";
import $ from 'jquery'
import {
    isIOS,
    isMobileOnly,
    isBrowser,
    isMobile
} from "react-device-detect";

const JobPlayVideo = (props) => {
    const [videoid, setVideoID] = useState('');
    useEffect(() => {
        var url = props.url;
        var regExp = /https:\/\/(www\.)?vimeo.com\/(\d+)($|\/)/;
        var match = url.match(regExp);

        if (match) {
            setVideoID(match[2]);
        }

    }, [])

    const rendercode = () => {

        var videoCompleted = false;
        var videoStarted = false;
        var videoDuration = 0;
        var videoPlayer;

        var checkVideoPlayer = document.getElementById("video_player");

        var videoPlay = function () {
            videoStarted = true;
            videoPlayer.getDuration().then(function (duration) { videoDuration = duration; });
        }

        var videoEnd = function (data) {
            videoCompleted = true;
        }

        var iframe = $('#player1')[0];
        videoPlayer = new window.Vimeo.Player(iframe);

        // When video starts begin tracking progress
        videoPlayer.on('play', videoPlay);

        videoPlayer.setLoop(false).then(function (loop) {
            console.log("loop set False");
        }).catch(function (error) {
            console.log("error setting loop");
        });
    }
    return (
        <React.Fragment>
        <LoadExternalScript
            src="https://player.vimeo.com/api/player.js"
            // async="true"
            defer="true"
            loadScript={rendercode}
        // appendScriptTo="head"
        />
            {videoid ?
                <div id="video_player">
                    <iframe src={`https://player.vimeo.com/video/` + videoid} id="player1" width="100%" height="200px" className="job-details-play-video mb-0"></iframe>
                </div>
                : <ReactPlayer
                    url={props.url}
                    className="job-details-play-video"
                    controls={true}
                    frameborder="0"
                    // muted={true}
                    autoplay={false}
                    playing={false}
                    loop={false}
                    width='100%'
                    height='200px'
                    previewTabIndex={0} light={false} />}
        </React.Fragment>
    )
};

export default JobPlayVideo;