import React, { useState, useRef, useEffect } from "react";
import { Link } from "gatsby";
import { Row, Col, Container, Card } from "react-bootstrap";
import { useQuery } from '@apollo/client';
import gql from "graphql-tag"
import ScrollAnimation from "react-animate-on-scroll";
import Slider from "react-slick";
import parse from 'html-react-parser';
import Dummy from "../../images/job-no-image.jpg"
import _ from 'lodash'

import NoImage from "../../images/arun-team-tile.png"
import { NewsDate } from "../common/site/functions"

function MoreJobs(props) {
    const settings = {
        dots: true,
        speed: 800,
        slidesToShow: 3,
        slidesToScroll: 2,
        infinite: true,
        arrows: false,
        mobileFirst: true,
        responsive: [
            {
                breakpoint: 1300,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: false,
                },
            },

            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: false,
                },
            },

            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };


    const branchImage = (id) => {
        var image = Dummy;
        if (!_.isEmpty(id)) {
            var branch = _.find(props.branches, { branch_id: id });
            if (!_.isEmpty(branch)) {
                if (branch.image.length > 0) {
                    image = branch.image[0].url;
                }
            }
        }
        return image;
    }

    return (
        <React.Fragment>
            <section className="guides-section-list more-similar-news similar-jobs-details">
                <Container>
                    <Row className="head">
                        <Col lg={12}>
                            <h2>Similar jobs...</h2>
                        </Col>
                    </Row>
                    <Row>
                        <Slider {...settings} className="guide-tile-slide">
                            {props.jobs && props.jobs.slice(0, 8).map((item, i) => {
                                return (
                                    <React.Fragment>
                                        {props.id != item.node.vacancy_id &&
                                            <Link to={`../${item.node.vacancy_id}`}>
                                                <div className="guide">
                                                    <img src={branchImage(item.node.branch_id)} alt="img" />
                                                    {/* <img src={item.Tile_Image ? item.Tile_Image.url : NoImage} alt={item.Tile_Image ? item.Tile_Image.alternativeText : item.Title} /> */}
                                                    <p className="title">{item.node.vacancy_title}</p>
                                                </div>
                                            </Link>
                                        }
                                    </React.Fragment>
                                )
                            })}
                        </Slider>
                    </Row>
                </Container>
            </section>
        </React.Fragment>
    )
}

export default MoreJobs;
