
import * as React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import _ from "lodash"
function JobStructuredData({ type, job, branchName }) {
  const cloud_url = process.env.GATSBY_CLOUD_URL;
  const site_url = process.env.GATSBY_SITE_URL;

  var salary = job.vacancy_salary_text.replace("Basic Salary ", "").split(' ')[0];
  salary = salary.replace(",", "").replace("£", "")

  var ldJson = {
    "@context": "http://schema.org",
    "@type": type,
    "title": job.vacancy_title,
    "baseSalary": {
      "@type": "MonetaryAmount",
      "name": "baseSalary",
      "value": parseInt(salary),
      "currency": "GBP"
    },
    "datePosted": job.published_at,
    "validThrough": "",
    "salaryCurrency": "GBP",
    "description": job.vacancy_short_description.replace(/<\/?[^>]+(>|$)/g, ""),
    "employmentType": "",
    "industry": "Real Estate",
    "jobLocation": {
      "@type": "Place",
      "address": {
        "@type": "PostalAddress",
        "addressLocality": job.vacancy_location,
        "addressRegion": "",
        "postalCode": "",
        "streetAddress": ""
      }
    },
    "hiringOrganization": {
      "@type": "Organization",
      "name": branchName == "wk" ? "Wards" : branchName == "cw" ? "Cubitt & West" : branchName == "da" ? "Douglas Allen" : branchName == "pt" ? "Pittis" : branchName == "fc" ? "Fine & Country" : branchName == "mmd" ? "Mortgage Matters Direct" : '',
      "url": branchName == "wk" ? "https://www.wardsofkent.co.uk/" : branchName == "cw" ? "https://www.cubittandwest.co.uk/" : branchName == "da" ? "https://www.douglasallen.co.uk/" : branchName == "pt" ? "https://www.pittis.co.uk/" : branchName == "fc" ? "https://www.fineandcountry.com/uk" : branchName == "mmd" ? "https://www.mortgagemattersdirect.co.uk" : '',
      "logo": {
        "@type": "ImageObject",
        "url": branchName == "wk" ? "https://www.wardsofkent.co.uk/images/logo.png" : branchName == "cw" ? "https://www.cubittandwest.co.uk/images/logo.png" : branchName == "da" ? "https://www.douglasallen.co.uk/images/logo.png" : branchName == "pt" ? "https://www.pittis.co.uk/images/logo.png" : branchName == "fc" ? "https://services-media.propertylogic.net/website/1446478647/fc_logo_website.svg" : branchName == "mmd" ? "https://mortgagemattersdirect.co.uk/app/themes/mmd-theme/dist/images/logo.svg" : '',
        "width": 243,
        "height": 34
      }
    },
    "mainEntityOfPage": {
      "@type": "WebPage",
      "@id": `${site_url}/vacancy/${job.vacancy_id}`
    }
  };
  return (
    <Helmet>
      <script type="application/ld+json">
        {JSON.stringify(ldJson, null, 2)}
      </script>
    </Helmet>
  )
}


export default JobStructuredData
