import React, { useState, useEffect } from "react";
import { useStaticQuery, graphql } from "gatsby";
import _ from "lodash"
import SEO from "../seo"
import Breadcrumb from "../Breadcrumbs/VacancyDetailsBreadcrumb"
import VacancyBody from "./VacancyDetails/VacancyBody"
import FormBlock from '../Home/FormBlock/FormBlock';
import logoBlack from "../../images/logo-dark.png";
import JobStructuredData from "../../components/Seo/JobStructuredData"
import { urlTrim } from "../../components/common/site/functions";
import RelatedJobs from "./RelatedJobs"
import moment from "moment";

function VacancyDetails(props) {
    const data = useStaticQuery(graphql`
    query {
        allJob(filter: {publish: {eq: true}}) {
          edges {
            node {
              branch_id
              vacancy_id
              vacancy_title
              vacancy_location
              vacancy_salary_text
              vacancy_short_description
              vacancy_category
              vacancy_closing_date
              vacancy_priority
              createdAt
            }
          }
        }
        glstrapi {
            globalModule {
                Vacancy_Detail_Page_Footer_Help {
                Content
                Name
                Select_Form {
                    Select
                }
                CTA_1_Label
                CTA_2_Label
                CTA_1_Link {
                    id
                }
                CTA_2_Link {
                    id
                }
                }
            }
        }
        allBranch(filter: {publish: {eq: true}}) {
            nodes {
              branch_id
              brand_id
              branch_name
              Banner_Image {
                alternativeText
                url
              }
              image{
                  url
              }
            }
        }
    }
  `);
    let globalModule = data.glstrapi.globalModule
    const branches = data.allBranch.nodes;
    const jobs = data.allJob.edges;
    var branch = _.find(branches, { branch_id: props.job_details?.branch_id });
    var job = null;
    var jobs_list = [];
    if (props.job_details) {
        job = props.job_details;

        if (job?.vacancy_category) {
            let list = _.filter(jobs, (e) => (urlTrim(job?.vacancy_category.replace("Fine & Country", "Sales").replace("Land & New Homes", "Sales").replace("Residential", "Sales").replace("Call Centre", "Sales").replace("Call Centre", "Sales").replace("Training", "administration-and-support").replace("Head Office", "administration-and-support").replace("Client Liaison", "administration-and-support")) === urlTrim(e.node.vacancy_category.replace("Fine & Country", "Sales").replace("Land & New Homes", "Sales").replace("Residential", "Sales").replace("Call Centre", "Sales").replace("Call Centre", "Sales").replace("Training", "administration-and-support").replace("Head Office", "administration-and-support").replace("Client Liaison", "administration-and-support")) && (moment(e.node.vacancy_closing_date != null ? e.node.vacancy_closing_date : "2100-01-01").isAfter(moment().format('YYYY-MM-DD'), 'day'))));
            if (job?.vacancy_category === "vacancies") {
                list = _.filter(jobs, (e) => ((moment(e.node.vacancy_closing_date != null ? e.node.vacancy_closing_date : "2100-01-01").isAfter(moment().format('YYYY-MM-DD'), 'day'))));
            }
            jobs_list = list
        }

    } else {
        <section className={"loader-wrapper"}>
            <div id="loader-wrapper">
                <div id="loader" class="new-loader">
                    <img className="logo-white loader-logo" src={logoBlack} alt="logo" />
                </div>
                <div class="loader-section section-left"></div>
                <div class="loader-section section-right"></div>
            </div>
        </section>
    }


    return (
        <React.Fragment>
            <SEO title={`${job.vacancy_title.replace("-", "in")}  | Arun Estates - Careers`} description={`Find the details about ${job.vacancy_title.replace("-", "in")}. At Arun Estates, We believe in not stopping until we are proud of what we have achieved for our customers, if you have the same belief and work ethic, apply now.`} />
            <JobStructuredData
                type="JobPosting"
                job={job}
                branchName={job ? job.brand_id : ''}
            />
            <Breadcrumb Pagename={job.vacancy_title} />
            <VacancyBody {...job} branches={branches} />
            <RelatedJobs branches={branches} id={job.vacancy_id} jobs={jobs_list}/>
            <FormBlock {...globalModule?.Vacancy_Detail_Page_Footer_Help} />
        </React.Fragment>
    )
}

export default VacancyDetails;
