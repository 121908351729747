import React from "react"
import { graphql } from "gatsby"
import { useQuery } from '@apollo/client';
import { Container, Row, Col } from "react-bootstrap"
import moment from "moment";
import _ from "lodash"
import gql from "graphql-tag"
import Header from "../components/Header/Header"
import Footer from "../components/Footer/Footer"
import VacancyDetails from "../components/Vacancies/VacancyDetails"
import ErrorPage from '../pages/404'
import logoBlack from "../images/logo-dark.svg";
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import "bootstrap/dist/css/bootstrap.min.css"
import "../scss/bootstrap-overrides.scss"
import "../scss/global.scss"
import "../scss/grunticon.scss"
import "../scss/custom.scss"
import "../scss/career-custom.scss"

const JobTemplate = props => {

  const pageurl = typeof window !== 'undefined' ? window.location.href : ''
  let myid_filt = pageurl.split('?');
  let mypageurl = myid_filt[0].replace(/\/$/, "");
  let property_slug = mypageurl.split('/');
  let id = property_slug[property_slug.length - 1];
  id = id.replace("/", "");

  
  var job = null;
  if (props.pageContext.job_details) {
    job = props.pageContext.job_details;
  }
  var showPage = true
  if ((job && job?.vacancy_closing_date && job?.vacancy_closing_date !== null && (moment(job?.vacancy_closing_date).isAfter(moment().format('YYYY-MM-DD'), 'day') == false)) || job?.publish == false) {
    showPage = false
  }

  return (
    <>
      {showPage ?
        <>
          <Header />
          <div className="wrapper vacancy-details">
            <VacancyDetails job_details={job} />
          </div>
          <Footer />
        </> : <ErrorPage job={job}/>
      }
    </>

  )
}
export default JobTemplate
