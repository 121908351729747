import React, { useState, useEffect } from "react";
import { Container, Row, Col, Tooltip, OverlayTrigger, Button } from "react-bootstrap";
import { Link } from "gatsby"
import ScrollAnimation from 'react-animate-on-scroll';
import parse from "html-react-parser";
import _ from "lodash"
import Dummy from "../../../images/job-no-image.jpg"
import NoImage from "../../../images/arun-team-tile.png"
import PlayVideo from "../../Play/JobDetails"
import { urlTrim } from "../../common/site/functions";
import ModalTiny from "react-bootstrap/Modal"
import JobAlert from "../../forms/job-details-send-message"
import {
    EmailShareButton,
    FacebookShareButton,
    LinkedinShareButton,
    TwitterShareButton,
    WhatsappShareButton
} from "react-share";

import {
    EmailIcon,
    FacebookIcon,
    LinkedinIcon,
    TwitterIcon,
    WhatsappIcon
} from "react-share";

function VacancyBody(props) {
    const shareurl = typeof window !== 'undefined' ? window.location.href : ''
    const [Shareicons, setShareicons] = React.useState(false);
    const [modalJobAlertformOpen, setJobAlertformOpen] = React.useState(false);
    const openShareicons = () => {
        setShareicons(true);
    }
    const closeShareicons = () => {
        setShareicons(false);
    }
    const closeJobAlertformModal = () => {
        setJobAlertformOpen(false);
    }
    const trackerShare = (event) => {
        var shareURL = typeof window !== 'undefined' ? window.location.href : ''
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            'event': 'Share - social icons',
            'formType': event + ' - ' + shareURL,
            'formId': 'Share',
            'formName': 'Share',
            'formLabel': 'Share'
        });
    }
    const branchImage = (id) => {
        var image = Dummy;
        if (!_.isEmpty(id)) {
            var branch = _.find(props.branches, { branch_id: id });
            if (!_.isEmpty(branch)) {
                if (branch.image.length > 0) {
                    image = branch.image[0].url;
                }
            }
        }
        return image;
    }

    return (
        <React.Fragment>
            <div className="video-block-wrap components--AreaGuideDetails--VideoBlock--VideoBlock">
                <Container>
                    <Row>
                        <Col lg="8">
                            <ScrollAnimation animateIn="fadeInUp" animateOnce>
                                <div class="vacancy-head">
                                    {/* <h1 className="h2">{props.vacancy_title.replace("-","in")}</h1> */}
                                    <h1 className="h2">{props.vacancy_title}</h1>
                                    <div className="vacancy-icons">
                                        <span className="text">
                                            <i className="icon-map-pin-light"></i>
                                            <span className="price">{props.vacancy_location}</span>
                                        </span>
                                    </div>
                                    <div className="vacancy-icons">
                                        <span className="text">
                                            <i className="icon-currency"></i>
                                            <span className="price">{props.vacancy_salary_text.replace("£","")}</span>
                                        </span>
                                    </div>
                                </div>
                                <div className={`block-content  no-image`}>
                                    <div className="block-text">

                                        {props.vacancy_long_description &&
                                            <>{parse(props.vacancy_long_description)}</>
                                        }
                                    </div>
                                </div>
                            </ScrollAnimation>


                        </Col>
                        <Col lg="4" className={`block-content block-text no-image sidebar`}>
                            <div className="guide-details-right-block main-banner">
                                <div className="">
                                    <div className="other-areas-item img-zoom">
                                        <div className="other-areas-img">
                                            {props.branch_id && props.vimeo_video_id == null &&
                                                < img src={branchImage(props.branch_id)} alt="img" />
                                            }
                                            {props.vimeo_video_id &&
                                                <PlayVideo url={props.vimeo_video_id} />
                                            }


                                        </div>
                                    </div>
                                    <div className="first">
                                        <div className="btn-wrap row">
                                            <Col lg={6} className="cta col-1">
                                                <Link to={`/vacancy/${props.vacancy_id}/apply`} className="btn btn-secondary">
                                                    Apply now
                                                </Link>
                                            </Col>
                                            <Col lg={6} className="cta col-2" onMouseLeave={closeShareicons}>
                                                {/* <Link to={`#`} className="btn">
                                                    Share Job
                                                </Link> */}
                                                <a className="btn" href={"javascript:;"} onClick={openShareicons} >
                                                    Share job
                                                </a>
                                                {Shareicons &&
                                                    <div onMouseEnter={openShareicons} className="property-share-social-icons">
                                                        <FacebookShareButton onClick={() => trackerShare('FacebookShareButton')} url={shareurl} className="my-share-button facebook-share">
                                                            <FacebookIcon size={32} round={false} borderRadius={`10`} />
                                                        </FacebookShareButton>
                                                        <TwitterShareButton onClick={() => trackerShare('TwitterShareButton')} url={shareurl} className="my-share-button twitter-share">
                                                            <TwitterIcon size={32} round={false} borderRadius={`10`} />
                                                        </TwitterShareButton>
                                                        <LinkedinShareButton onClick={() => trackerShare('LinkedinShareButton')} url={shareurl} className="my-share-button linkedin-share">
                                                            <LinkedinIcon size={32} round={false} borderRadius={`10`} />
                                                        </LinkedinShareButton>
                                                        <WhatsappShareButton onClick={() => trackerShare('WhatsappShareButton')} url={shareurl} className="my-share-button whatsapp-share">
                                                            <WhatsappIcon size={32} round={false} borderRadius={`10`} />
                                                        </WhatsappShareButton>
                                                    </div>
                                                }
                                            </Col>
                                        </div>

                                        {props.consultant_name &&
                                            <div className="advisor-info">
                                                {props.consultant_image &&
                                                    <div className="advisor-img">
                                                        <img src={props.consultant_image ? props.consultant_image : NoImage} alt={`Vacancy Detail video`} />
                                                    </div>
                                                }
                                                <div className="advisor-contact">
                                                    <span className="advisor-name">{props.consultant_name}</span>
                                                    <span>&nbsp;</span>
                                                    {/* <span className="advisor-designation">{props.consultant_title}</span> */}

                                                    <a href="javascript:void(0);" onClick={() => { setJobAlertformOpen(true); }} className="phone-number">
                                                        <i className="icon-envelope"></i>
                                                        <span className="consultant_email">Send a message</span>
                                                    </a>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                                <div className="info-text">
                                    <p><b> Not quite right for you?</b> Browse our other job openings to find your next career.</p>
                                    <Link to={`/vacancy-type/${urlTrim(props.vacancy_category)}`} className="btn">View all vacancies</Link>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <ModalTiny show={modalJobAlertformOpen} onHide={closeJobAlertformModal} className="book-a-viewing-modal modal-main-form">
                <ModalTiny.Header closeButton className="contact-close-btn">
                    <ModalTiny.Title className="w-100"><h2 className="text-center mb-0">Contact Us</h2>
                    </ModalTiny.Title>
                </ModalTiny.Header>
                <ModalTiny.Body className="event-section people-details-form-modal"><JobAlert /></ModalTiny.Body>
            </ModalTiny>
        </React.Fragment>
    )
}

export default VacancyBody;
